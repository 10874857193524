<template>
  <div class="bedBox fatherHeight">
    <div class="bedList-serach flex-between">
      <div class="serach-left">
        <el-form
          :inline="true"
          :model="tableSearch"
          ref="searchForm"
          class="demo-form-inline w-144"
        >
          <el-date-picker
            v-model="tableSearch.time"
            type="daterange"
            value-format="YYYY-MM-DD"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getmachineRunList"
          >
          </el-date-picker>
          <el-select
            class="hospital"
            v-model="tableSearch.hospital_area_id"
            @change="getDataList"
            placeholder="请选择"
          >
            <el-option
              v-for="item in hospitalList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="tableSearch.run_status"
            @change="getDataList"
            placeholder="请选择"
          >
            <el-option label="正常" :value="1"></el-option>
            <el-option label="维修" :value="2"></el-option>
            <el-option label="报废" :value="3"></el-option>
          </el-select>
        </el-form>
      </div>
      <div class="serach-right">
        <el-button @click="addBed" class="color-green" type="primary">
          <i class="fa fa-plus-circle"></i>新增
        </el-button>
        <!-- <el-button class="color-vice" type="primary">
          <i class="fa fa-arrow-down"></i>导出
        </el-button> -->
      </div>
    </div>
    <div class="table flex-start" ref="tableBoxRef">
      <div class="table-left">
        <el-table
          v-loading="dataListLoading"
          ref="singleTableRef"
          :data="tableData"
          class="table-ellipsis"
          highlight-current-row
          size="small"
          border
          @current-change="getmachineRunList"
        >
          <el-table-column fixed prop="machine_no" label="设备编码" />
          <el-table-column
            show-overflow-tooltip
            prop="machine_name"
            label="名称"
          />
        </el-table>
        <div class="pagination-box">
          <el-pagination
            :currentPage="pageData.currentPage"
            :page-size="pageData.pageSize"
            :pager-count="5"
            :background="true"
            layout="prev, pager, next"
            :total="pageData.totalSum"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div class="table-right">
        <el-table
          v-loading="equipmentLoading"
          :data="equipmentData"
          class="table-ellipsis"
          size="small"
          border
        >
          <el-table-column prop="record_time" label="日期" width="160" />
          <el-table-column prop="open_user.name" label="开机人" width="90" />
          <el-table-column prop="open_time" label="开机时间" width="160" />
          <el-table-column prop="run_content" label="运行情况" />
          <el-table-column prop="close_user.name" label="关机人" width="90" />
          <el-table-column prop="close_time" label="关机时间" width="160" />
          <el-table-column fixed="right" label="操作" width="100">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="modParient(scope.row)"
              >
                <i class="txIons modify"></i>
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="delParient(scope.row)"
              >
                <i class="txIons del"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box" v-if="equipmentData.length > 0">
          <el-pagination
            :currentPage="pageData2.currentPage"
            :page-size="pageData2.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData2.totalSum"
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Add ref="addRef" @refreshDataList="getmachineRunList"></Add>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, defineExpose, nextTick } from 'vue'
import service from '@/utils/request'
import dayjs from 'dayjs'
import Add from './add.vue'
import { ElMessage, ElMessageBox } from 'element-plus'

const userInfo = localStorage.getItem('ms_userData')
const dataListLoading = ref(false)
const istable = ref(true)
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const pageData2 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const tableSearch = reactive({
  spital_area_id: userInfo.hospital,
  run_status: 1,
  time: [],
})
const tableData = ref([])
const equipmentData = ref([])
const tableBoxRef = ref(null)
const singleTableRef = ref(null)
const tableHei = ref(0)
const equipmentLoading = ref(false)
const machineRun = reactive({
  machine_id: '',
})

onMounted(() => {
  istable.value = false
  // console.log(tableBoxRef.value.clientHeight)
  tableHei.value = tableBoxRef.value.clientHeight - 40
  nextTick(() => {
    istable.value = true
  })
  initData()
  getHospitalList()
})
const initData = () => {
  const startTime = dayjs(new Date()).subtract(29, 'day').format('YYYY-MM-DD')
  const endTime = dayjs(new Date()).format('YYYY-MM-DD')
  tableSearch.time = [startTime, endTime]
  handleSearch()
}
defineExpose({
  initData,
})
const handleSearch = () => {
  pageData.currentPage = 1
  getDataList()
}

const handleSizeChange = (val) => {
  pageData.pageSize = val
  getDataList()
}
const handleCurrentChange = (val) => {
  pageData.currentPage = val
  getDataList()
}
const getDataList = async () => {
  tableSearch.current = pageData.currentPage
  tableSearch.size = pageData.pageSize
  dataListLoading.value = true
  let res = await service.post('/api/machine/list', tableSearch)
  dataListLoading.value = false
  if (res.code === 0) {
    tableData.value = res.data.records
    pageData.totalSum = res.data.total
    machineRun.machine_id = ''
    equipmentData.value = []
    if (tableData.value.length > 0) {
      singleTableRef.value.setCurrentRow(tableData.value[0])
    }
  }
}
const handleSizeChange2 = (val) => {
  pageData2.pageSize = val
  getmachineRunList()
}
const handleCurrentChange2 = (val) => {
  pageData2.currentPage = val
  getmachineRunList()
}
const getmachineRunList = async (item) => {
  machineRun.current = pageData2.currentPage
  machineRun.size = pageData2.pageSize
  if (item && item.id) {
    machineRun.machine_id = item.id
  }
  if (!machineRun.machine_id) return
  if (tableSearch.time.length > 0) {
    machineRun.start_time = tableSearch.time[0]
    machineRun.end_time = tableSearch.time[1]
  }
  equipmentLoading.value = true
  let res = await service.post('/api/machine_run/list', machineRun)
  equipmentLoading.value = false
  if (res.code === 0) {
    equipmentData.value = res.data.records
    pageData2.totalSum = res.data.total
  }
}

const hospitalList = ref([])
const getHospitalList = async () => {
  let res = await service.post('/api/hospital/list', {
    parent_id: 0,
  })
  if (res.code === 0) {
    hospitalList.value = res.data
    if (!tableSearch.hospital_area_id) {
      tableSearch.hospital_area_id = res.data[0].id
    }
  }
}
const addRef = ref(null)
const addBed = () => {
  if (!machineRun.machine_id) {
    ElMessage.error('请先选择机器')
    return
  }
  addRef.value.initData({
    machine_id: machineRun.machine_id,
  })
}
const modParient = (item) => {
  addRef.value.initData(item)
}
const delParient = (item) => {
  ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      let res = await service.post('/api/machine_run/delete', {
        id: item.id,
      })
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getmachineRunList()
      }
    })
    .catch(() => {})
}
</script>

<style scoped lang="scss">
.bedList-serach {
  padding-top: 2px;
  &:deep(.el-date-editor) {
    width: 280px;
    margin-right: 10px;
    top: 2px;
  }
}
.table {
  height: calc(100% - 46px);
  .table-left {
    width: 240px;
    margin-right: 8px;
  }
  .table-right {
    width: calc(100% - 248px);
  }
}
.hospital {
  margin-right: 10px;
}
.demo-form-inline .w-208 {
  width: 208px;
}
</style>
