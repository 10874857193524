<template>
  <div class="consumables">
    <BaseDialog
      :title="ruleForm.id ? '修改运行记录' : '新增运行记录'"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="900px"
      top="10%"
    >
      <div class="consumables-search">
        <el-form
          :model="ruleForm"
          label-width="110px"
          label-position="right"
          :inline="true"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="记录日期：">
            <el-date-picker
              v-model="ruleForm.record_time"
              type="datetime"
              :clearable="false"
              value-format="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择建立日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="开机人：">
            <el-select v-model="ruleForm.open_uid" placeholder="请选择">
              <el-option
                v-for="item in userNameList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开机时间：">
            <el-date-picker
              v-model="ruleForm.open_time"
              type="datetime"
              :clearable="false"
              value-format="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择建立日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关机人：">
            <el-select v-model="ruleForm.close_uid" placeholder="请选择">
              <el-option
                v-for="item in userNameList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关机时间：">
            <el-date-picker
              v-model="ruleForm.close_time"
              type="datetime"
              :clearable="false"
              value-format="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择建立日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item class="addr" label="运行情况：">
            <el-input
              v-model="ruleForm.run_content"
              type="textarea"
              :rows="4"
              placeholder="请输入运行情况"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="savePatient"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import { reactive, ref, defineExpose, defineEmits } from 'vue'
import service from '@/utils/request'
import BaseDialog from '@/components/Dialog/index.vue'
import { ElMessage } from 'element-plus'
import { ruleTimeYear } from '@/utils/tool.js'

const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
const userNameList = reactive([
  {
    name: localStorage.getItem('ms_username'),
    code: userInfo.uid,
    id: userInfo.uid,
  },
])
const ruleForm = ref({})
ruleForm.value.record_time = ruleTimeYear(new Date())
ruleForm.value.open_time = ruleTimeYear(new Date())
ruleForm.value.close_time = ruleTimeYear(new Date())
const visible = ref(false)
const autograph = reactive({
  close_uid: '',
  open_uid: '',
})

const initData = (item) => {
  visible.value = true
  if (item.id) {
    ruleForm.value = item
    if (item.close_uid && item.close_uid !== userInfo.uid) {
      autograph.close_uid = JSON.parse(JSON.stringify(item)).close_uid
      ruleForm.value.close_uid = item.close_user.name
    }
    if (item.open_uid && item.open_uid !== userInfo.uid) {
      autograph.open_uid = JSON.parse(JSON.stringify(item)).open_uid
      ruleForm.value.open_uid = item.open_user.name
    }
  } else {
    ruleForm.value = {
      machine_id: item.machine_id,
      record_time: ruleTimeYear(new Date()),
      open_time: ruleTimeYear(new Date()),
      close_time: ruleTimeYear(new Date()),
    }
  }
}

// 保存
const saveLoad = ref(false)
const em = defineEmits(['refreshDataList'])
const savePatient = async () => {
  let url = ''
  if (ruleForm.value.id) {
    url = '/api/machine_run/edit'
  } else {
    url = '/api/machine_run/create'
  }
  if (ruleForm.value.close_uid !== userInfo.uid) {
    ruleForm.value.close_uid = autograph.close_uid
  }
  if (ruleForm.value.open_uid !== userInfo.uid) {
    ruleForm.value.open_uid = autograph.open_uid
  }
  saveLoad.value = true
  let res = await service.post(url, ruleForm.value)
  saveLoad.value = false
  if (res.code === 0) {
    ElMessage.success(res.msg)
    visible.value = false
    em('refreshDataList')
  }
}

defineExpose({
  initData,
})
</script>

<style scoped lang="scss">
.consumables-search {
  margin-top: 16px;
  .el-form-item {
    width: 47%;
    margin-right: 6%;
    &:nth-child(2n) {
      margin-right: 0;
    }
    &.addr {
      width: 100%;
      margin-right: 0;
    }
  }
}
</style>
